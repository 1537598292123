
import { defineComponent, reactive, ref, onMounted } from "vue";
import { useRouter, useRoute } from "vue-router";
import Header from "@/components/Header.vue";
import Utils from "@/utils/Utils";
import SystemUtils from "@/utils/SystemUtils";
import regExps from "@/utils/regExps";

import ApprovalStep from "@/components/attendance/approvalStep.vue";
// import { VacationLeaveService } from "@/services/attendance/leave/VacationLeaveService";
import { WorkflowService } from "@/services/attendance/workflow/WorkflowService";
import { TravelService } from "@/services/attendance/travel/TravelService";
import {
  FileAttendanceHelper,
  UploadStatus,
  UploadMessage,
} from "@/utils/FileAttendanceHelper";
import { stringify } from "querystring";
export default defineComponent({
  name: "TravelInfo",
  components: { Header, ApprovalStep },

  setup() {
    const router = useRouter();
    const query = useRoute().query;
    const api = new TravelService();
    const WorkflowApi = new WorkflowService();
    let isMore = ref(false);
    let approval = reactive({
      applyTime: "",
      approveStatus: 3,
      endNoon: "",
      endTime: "",
      id: "",
      orgId: "",
      orgName: "",
      procId: "",
      procStatus: 1,
      reason: "",
      startNoon: "",
      startTime: "",
      travelUser: "",
      travelUserIdnumber: null,
      travelUserTel: "",
      trips: [
        {
          id: "",
          attachments: new Array(),
        },
      ],
      userId: "",
      userName: "",
    });
    const stepList = ref(Array);
    let attachments = reactive({ data: new Array() });
    let title = ref("出差审批");
    let handleType = ref();
    let userInfo = SystemUtils.loginUser;
    let canRollBack = ref();
    let btnIconRight = reactive(["icon63huanyuan"]);
    let btnSizeRight = reactive(["34px"]);
    const tripsObj = reactive({
      trips: new Array(),
    });
    const signInFiles = reactive({
      data: new Array<any>(),
      progress: "0",
    });
    const isRLGP = ref(false);
    const initData = () => {
      if (query) {
        handleType.value = query.handleType;
        getInfo();
        getTaskList();
        getDeal();
        if (handleType.value != 0) {
          title.value = "出差详情";
        } else {
          let params = { procId: query.procId };
          WorkflowApi.getProcInfo(params)
            .then((res: any) => {
              let currentActi = res.currentActi;
              let stepList = res.tasks;
              let last = stepList[stepList.length - 1];
              if (
                last.approveResult == 0 &&
                currentActi == "专员购票" &&
                last.taskAssigneeName == userInfo.nameCn
              ) {
                isRLGP.value = true;
              }
            })
            .catch(() => {});
        }
      }
    };
    //获取出差详情
    const getInfo = () => {
      let params = {
        id: query.id,
      };
      api
        .getInfo(params)
        .then((res: any) => {
          if (res.userId == userInfo.id && res.approveStatus == 0) {
            canStopHandle();
          }
          //数据处理
          for (let index = 0; index < res.trips.length; index++) {
            let tripItem = res.trips[index];
            let copyatt = [];
            for (const attachmentsItem of tripItem.attachments) {
              if (attachmentsItem) {
                let obj = {
                  url: attachmentsItem,
                  status: UploadStatus.Done,
                  message: UploadMessage.Done,
                  isImage: true,
                };
                copyatt.push(obj);
              }
            }
            tripItem.attachments = copyatt;
          }
          approval.trips = res.trips;
          approval.startNoon = res.startNoon;
          approval.startTime = res.startTime;
          approval.endNoon = res.endNoon;
          approval.endTime = res.endTime;
          approval.reason = res.reason;
        })
        .catch((err) => {
          Utils.presentToastWarning(err.data.error);
          router.go(-1);
        });
    };
    //获取审批流程
    const getTaskList = () => {
      let params = {
        procId: query.procId,
      };
      WorkflowApi.getTaskList(params).then((res: any) => {
        stepList.value = res;
      });
    };
    //是否显示审批按钮
    const getDeal = () => {
      let params = {
        procId: query.procId,
        taskId: query.taskId,
      };
      WorkflowApi.isDealDoneWorkTask(params).then((res: any) => {
        if (res) {
          handleType.value = 2;
        }
      });
    };
    //是否可撤回
    const canStopHandle = () => {
      let params = {
        procId: query.procId,
      };
      WorkflowApi.canRollBackWorkTask(params).then((res: any) => {
        canRollBack.value = res;
      });
    };
    //撤回
    const onFiltClick = () => {
      if (!query.procId) {
        Utils.presentToastWarning("参数异常");
        return;
      }
      let params = {
        procId: query.procId,
      };
      Utils.confirmDialog("撤回", "请确认是撤回出差申请", function () {
        WorkflowApi.forceStopWorkTask(params)
          .then((res: any) => {
            Utils.presentToastWarning("已成功撤回！");
            router.go(-1);
          })
          .catch(() => {
            Utils.presentToastWarning("撤回失败！");
          });
      });
    };
    const subStringUserName = (userName: string) => {
      if (!userName) {
        return "";
      }
      return Utils.subStringUserName(userName, 2);
    };
    //审批同意
    const agreeHandle = () => {
      Utils.confirmDialog("同意", "请确认是同意出差申请", function () {
        submitHandle(1);
      });
    };
    let refuseReason = ref();
    //审批不同意
    const refuseHandle = () => {
      Utils.confirmDialog(
        "不同意",
        "<div><textarea style='height:50px;' class='alert-textarea' id='refuseReason' placeholder='请输入不同意原因' rows='8' maxlength='100' :required='true'></textarea></div>",
        function () {
          let textarea = document.getElementById(
            "refuseReason"
          ) as HTMLTextAreaElement;
          let val = textarea.value;
          let textareaBoolen = true;
          if (val.replace(/\s+/g, "") == "") {
            Utils.presentToastWarning("请输入不同意原因");
            textareaBoolen = false;
          }
          if (regExps.SpecChar.test(val)) {
            Utils.presentToastWarning("不同意原因不能输入特殊字符哦！");
            textareaBoolen = false;
          }
          if (textareaBoolen) {
            refuseReason.value = val;
            submitHandle(2);
          }
        }
      );
    };
    //完成购票
    const ticketDone = (id: string) => {
      Utils.confirmDialog("确认", "请确认是否已完成购票", function () {
        api
          .doneTicket(id)
          .then((res: any) => {
            getInfo();
            Utils.presentToast("操作成功");
          })
          .catch(() => {
            Utils.presentToast("操作失败");
          });
      });
    };
    const submitHandle = (type: any) => {
      let approveStatus = type == 1 ? true : false;
      let paramSend = {
        procId: query.procId,
        taskId: query.taskId,
        listReciveVar: [
          {
            key: "approve",
            value: approveStatus,
          },
        ],
        option: refuseReason.value,
      };
      WorkflowApi.send(paramSend)
        .then((res: any) => {
          Utils.presentToast("审批成功");
          router.go(-1);
        })
        .catch((err: any) => {
          Utils.presentToastWarning(err);
        });
    };
    const changeisMore = () => {
      isMore.value = true;
    };
    const fileAttendanceHelper = new FileAttendanceHelper();

    //上传图片
    const bindUploadPicture = async (index: number) => {
      //打开选择框
      fileAttendanceHelper.openFileSheet(
        (path: any) => {
          if (path) {
            let pic = {
              url: "",
              status: UploadStatus.Uploading,
              message: UploadMessage.Uploading,
              isImage: true,
            };
            approval.trips[index].attachments.push(pic);

            fileAttendanceHelper
              .uploadFile(
                path,
                api.getUploadFilePath(),
                approval.trips[index].id
              )
              .then(
                (result: any) => {
                  const i: number =
                    approval.trips[index].attachments.length - 1;
                  approval.trips[index].attachments[i].url = result.httpPath;
                  approval.trips[index].attachments[i].status =
                    UploadStatus.Done;
                  approval.trips[index].attachments[i].message =
                    UploadMessage.Done;
                },
                (e: any) => {
                  approval.trips[index].attachments.splice(
                    approval.trips[index].attachments.length - 1,
                    1
                  );
                }
              );
          }
        },
        (error: string) => {
          Utils.presentToastWarning(error);
        }
      );
    };
    const bindOverSize = (file: any) => {
      Utils.presentToastWarning("文件大小不能超过10MB");
    };
    //删除文件
    const bindDeletePicture = (ev: any, id: string) => {
      //  console.log(ev.url);
      // let params = {
      //   path: ev.url,
      //   id: id,
      // };
      // console.log(params);
      api
        .deleteFile(id,ev.url)
        .then((res: any) => {
          getInfo();
          Utils.presentToast("操作成功");
        })
        .catch((err: any) => {
          Utils.presentToast(err);
        });
    };

    onMounted(() => {
      initData();
    });

    return {
      title,
      btnIconRight,
      btnSizeRight,
      handleType,
      approval,
      stepList,
      attachments,
      isMore,
      subStringUserName,
      changeisMore,
      agreeHandle,
      canRollBack,
      onFiltClick,
      refuseHandle,
      signInFiles,
      bindUploadPicture,
      bindOverSize,
      bindDeletePicture,
      isRLGP,
      ticketDone,
    };
  },
});
